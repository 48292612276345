import { Link } from 'react-scroll';
import '../css/Navbar.css';
import logo from '../../images/navbar/navlogo.png';

const Navbar = () => {
  return (
      <div className="wrapper-next-nav">
        <div className='logo_bar'>
        <img src={logo} alt="Logo" />
        <span className="text-logo">Chainfinity</span>
        </div>
        <ul className="nav-links">
          <li>
            <Link
              activeClass="active-link"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Our Services
            </Link>
          </li>
          {/* <li>
            <Link
              activeClass="active-link"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Get in touch
            </Link>
          </li> */}
        </ul>
      </div>
  );
};

export default Navbar;