import { useEffect } from "react";
import React from "react";
import "../css/Hero.css";
import giff from "../../images/home/heroimg.png";

function Hero() {
  const handleGetInTouch = () => {
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };

  useEffect(() => {
    // This useEffect will run when the component mounts
    // Load the Tawk.to script asynchronously here
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/64d886dacc26a871b02ef01c/1h7msti0g';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Clean up the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section id="hero">
      <div className="Hero">
        <div className="container">
          <div className="Hero-row">
            <div className="col-l-6">
              <div className="Hero-text text-left mt-5">
                <h4>Redefine Web3 and Beyond</h4>
                <h1 className="title-font">
                  <span className="text-customer">
                    Decentralizing Businesses with </span> Seamless, Smart, Secure Solutions
                </h1>
                <p className="title-font3">
                  Our mission is to revolutionize industries by harnessing the
                  power of smart contracts. Our smart contract solutions offer
                  unparalleled transparency, reducing inefficiencies,
                  eliminating intermediaries, and enhancing overall operational
                  effectiveness.
                </p>

                <button type="button" className="on on-submit" onClick={handleGetInTouch}>
                  Get in Touch
                </button>
              </div>
            </div>
            <div>
              <img className="giff_class" src={giff} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
