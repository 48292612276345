import App from './App';
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from "./serviceWorker";
import Loader from './components/js/Loader';
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
