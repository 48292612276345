import { Fragment } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Home from './components/js/Home';
import Service from './components/js/OurService';
import Footer from './components/js/Footer';

function App() {
  return (
    <Fragment>
      <div className="App">
        <TawkMessengerReact
          propertyId="64d886dacc26a871b02ef01c"
          widgetId="1h7msti0g" />
      </div>
      <Home />
      <Service />
      <Footer />
    </Fragment>
  );
}

export default App;