import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
// import backgroundImage from "../../images/home/homelogo.png";
import "../../App.css";

function Home() {
  return (
    <section id="home" className="home_class">
      {/* <div
        className="lead-background"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      /> */}
      <div className="container">
        <div className="nav_bar">
          <Navbar />
        </div>
        <Hero />
      </div>
    </section>
  );
}

export default Home;
