import React from "react";
import "../css/Contact.css";
import logo from "../../images/contact/logowhite.png";

const Footer = () => {
  return (
    <div className="container">
      <div className="wrapper-next">
        <div className="rights">
          <div className="alignlogo">
            <img src={logo} alt="Logo" />
            <span className="text-logo" style={{ color: "white" }}>
              Chainfinity
            </span>
          </div>
          <div className="copyright">
            <p>© 2023 Chainfinity Pte Ltd., Singapore. All rights reserved.</p>
          </div>
        </div>
        <ul className="contact-list">
          <li className="contact-item">
            <div>
              <a
                href="mailto:contact.chainfinity@gmail.com"
                className="contact-item-link"
              >
                Email: contact.chainfinity@gmail.com
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="hr_padding">
        <hr class="horizontal-line" />
      </div>
    </div>
  );
};

export default Footer;