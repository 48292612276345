import React from 'react';
import Dev from "../../images/service/coding.png";
import Int from "../../images/service/integration.png";
import TestRev from "../../images/service/product-review.png";
import Dapp from "../../images/service/discussion.png";

import '../css/OurService.css';

const OurService = () => {

    const serviceIcons = [
        {
            "id": "1",
            "title": "Smart Contract Development",
            "image": Dev,
            "description": "Secure, scalable and gas-efficient smartcontract development on all EVM compatible blockchains, ensuring seamless execution and compatibility. "
        },
        {
            "id": "2",
            "title": "Smart Contract Integration",
            "image": Int,
            "description": "Comprehensive smart contract integration services with external protocols like Aave, Uniswap, Compound, Balancer, etc., to ensure seamless interoperability and maximize the potential of your dApp."
        },
        {
            "id": "3",
            "title": "Smart Contract Testing & Review",
            "image": TestRev,
            "description": "Comprehensive testing and review services to ensure the quality, security, and reliability of your smart contracts."
        },
        {
            "id": "4",
            "title": "dAPP Consulting",
            "image": Dapp,
            "description": "Expert guidance and support to help you navigate the world of decentralized applications (DApps) and harness their potential for your business."
        },
    ]

    return (
        <section id="service">
            <div className="services section-title mt-5">
                <div className="container text-white">
                    <div className="row align-items-left">
                        <div className="col-md-7 mx-auto">
                            <div className="services-title mb-5 mt-5">
                                <h1 className="title-font title-font-size">
                                    Services
                                </h1>
                                <p className="title-text mt-4 mb-4 title-font-2">
                                    Our services are built for your business needs
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row align-items-center">
                        {
                            serviceIcons.map(service => (


                                <div className="col-6 col-lg-6" key={service.id}>
                                    <div className="service-boxes">
                                        <div className="service-icon">
                                            <img className="service-icon-img mb-3" src={service.image} alt="instagram" />
                                        </div>
                                        <div className="service-info-title">
                                            <h3 className="service-name title-font-2">
                                                {service.title}
                                            </h3>
                                        </div>
                                        <div className="service-info">
                                            <p className="service-description text-left title-font-2 mt-3">
                                                {service.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurService;
