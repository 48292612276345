import React from 'react';
import '../css/Loader.css';

const Loader = () => {
    return (
        <div className="loading-container">
            <progress className="progress-circular"></progress>
        </div>
    );
}
 
export default Loader;